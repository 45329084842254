import { useEffect, useState } from "react";

import DizeeInput from "../../../components/DizeeInput";
import { verifyPhone } from "../../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../../store/alert/alertSlice";

const Step0 = ({ setStep, setUser, user }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const { sentOTP } = useSelector((state) => state.auth);
  const { email } = useSelector((state) => state.user);

  useEffect(() => {
    if (sentOTP) setStep(1);
  }, [sentOTP, setStep]);
  const onChangePhone = (e) => {
    setPhone(e.target.value);
    setUser({ ...user, phone: e.target.value });
  };

  const sendVerifyCode = () => {
    dispatch(clearAlerts())
    dispatch(verifyPhone(phone));
  };

  return (
    <div>
      <div className="font-default mb-9">Enter your phone number</div>
      <div className="mb-20">
        <DizeeInput
          placeholder={"Mobile phone #"}
          value={phone}
          onChange={onChangePhone}
        />
      </div>
      <div className="font-default cursor-pointer" onClick={sendVerifyCode}>
        Send verification code
      </div>
    </div>
  );
};

export default Step0;
