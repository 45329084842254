import { useEffect, useState } from "react";
import DizeeInput from "../../../components/DizeeInput";
import { verifyOTP } from "../../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";

const Step1 = ({ setStep }) => {
  const dispatch = useDispatch();
  const { phone } = useSelector((state) => state.user);
  const { otpVerified } = useSelector((state) => state.auth);
  const [code, setCode] = useState("");

  useEffect(() => {
    if (code.length === 6) {
      dispatch(verifyOTP({ code, phone }));
    }
  }, [code, dispatch, phone]);
  useEffect(() => {
    if (otpVerified) setStep(2);
  }, [otpVerified, setStep]);
  return (
    <div>
      <div className="font-default mb-9">Verify your phone number</div>
      <div className="mb-20">
        <DizeeInput
          placeholder={"Enter the code"}
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
      </div>
    </div>
  );
};

export default Step1;
