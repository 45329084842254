import Check from "../../../svg/Check";

const Step6 = ({ setStep, setUser, user }) => {
  return (
    <div>
      <div className="font-default mb-9">What describes you best?</div>
      <div className="mb-20 flex gap-[10px] sm:gap-[20px] font-default">
        <div
          className="cursor-pointer flex justify-center items-center"
          onClick={() => setUser({ ...user, hobbyType: "musician" })}
        >
          Musician
          <div className="ml-2 mt-[2px] h-[12px] w-[18px] flex items-center justify-center">
            {user.hobbyType === "musician" && <Check className="h-full w-full mt-[2px]" />}
          </div>
        </div>
        <div
          className="cursor-pointer flex justify-center items-center"
          onClick={() => setUser({ ...user, hobbyType: "creator" })}
        >
          Creator
          <div className="ml-2 mt-[2px] h-[12px] w-[18px] flex items-center justify-center">
            {user.hobbyType === "creator" && <Check className="h-full w-full mt-[2px]" />}
          </div>
        </div>
        <div
          className="cursor-pointer flex justify-center items-center"
          onClick={() => setUser({ ...user, hobbyType: "brand" })}
        >
          Brand
          <div className="ml-2 mt-[2px] h-[12px] w-[18px] flex items-center justify-center">
            {user.hobbyType === "brand" && <Check className="h-full w-full mt-[2px]" />}
          </div>
        </div>
        <div
          className="cursor-pointer flex justify-center items-center"
          onClick={() => setUser({ ...user, hobbyType: "personality" })}
        >
          Personality
          <div className="ml-2 mt-[2px] h-[12px] w-[18px] flex items-center justify-center">
            {user.hobbyType === "personality" && <Check className="h-full w-full mt-[2px]" />}
          </div>
        </div>
      </div>
      <div className="font-default flex">
        <div
          className={`mr-[43px] cursor-pointer ${user.hobbyType !== undefined ? "font-default" : "font-gray"}`}
          onClick={() => {
            if (user.hobbyType !== undefined) {
              setStep(7);
            }
          }}
        >
          Next
        </div>
        <div className="cursor-pointer" onClick={() => setStep(5)}>
          Go back
        </div>
      </div>
    </div>

  );
};

export default Step6;
