import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../../store/alert/alertSlice";
import { stripePaymentThunk } from "../../../store/auth/authThunk";
import Check from "../../../svg/Check";
import { postDataAPI } from "../../../utils/fetchData";

const Step3 = ({ setStep, setUser, user }) => {
  const [planType, setPlanType] = useState(0);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchApi = async () => {
      try {
        await postDataAPI('stripe/get_payment_history', { phone: userData?.phone, email: userData?.email }).then((res) => {
          if (res.data.data.response) {
            setStep(4)
          }
        })
      } catch (error) {
        console.log('error', error)
      }

    }
    fetchApi()
  }, [planType])

  useEffect(() => {
    if (user && typeof user.plan === 'number') {
      if (user.plan <= 1) setPlanType(0); // Monthly plans: 0 (Basic), 1 (Professional)
      else setPlanType(1); // Yearly plans: 2 (Basic), 3 (Professional)
    }
  }, [user]);

  const handleStripePayment = async (plan) => {
    const persistedData = localStorage.getItem('persist:root');
    const parsedData = JSON.parse(persistedData);
    let userData = JSON.parse(parsedData.user);
    let phone = userData.phone;
    const url = new URL(window.location.href);
    const emailParam = url.searchParams.get('email');
    let email = emailParam;
    let userDataState = JSON.stringify(user);
    dispatch(clearAlerts());
    console.log('plan', plan)
    dispatch(stripePaymentThunk({ plan, user: userDataState, email, phone }));
  };

  const handlePlanTypeChange = (type) => {
    setPlanType(type);
    if (type === 0) {
      // Set to Monthly Basic if switching to Monthly
      setUser({ ...user, plan: 0 });
    } else {
      // Set to Yearly Basic if switching to Yearly
      setUser({ ...user, plan: 2 });
    }
  };

  return (
    // <div>
    //   <div className="font-default mb-9">Select a free plan for 30 days</div>
    //   <div className="font-default gap-[30px] flex mb-9">
    //     <div className="cursor-pointer flex justify-center items-center" onClick={() => handlePlanTypeChange(0)}>
    //       Monthly
    //       <div className="ml-2 mt-[2px] h-[12px] w-[18px]">
    //         {planType === 0 ? <Check className="h-full w-full" /> : null}
    //       </div>
    //     </div>
    //     <div className="cursor-pointer flex justify-center items-center" onClick={() => handlePlanTypeChange(1)}>
    //       Yearly
    //       <div className="ml-2 mt-[2px] h-[12px] w-[18px]">
    //         {planType === 1 ? <Check className="h-full w-full" /> : null}
    //       </div>
    //     </div>
    //   </div>

    //   {planType === 0 && (
    //     <div className="mb-20 flex gap-[30px] font-default">
    //       <div
    //         className="cursor-pointer flex justify-center items-center"
    //         onClick={() => setUser({ ...user, plan: 0 })}
    //       >
    //         Basic
    //         <div className="ml-2 mt-[2px] h-[12px] w-[18px]">
    //           {user?.plan === 0 ? <Check className="h-full w-full" /> : null}
    //         </div>
    //       </div>
    //       <div
    //         className="cursor-pointer flex justify-center items-center"
    //         onClick={() => setUser({ ...user, plan: 1 })}
    //       >
    //         Professional
    //         <div className="ml-2 mt-[2px] h-[12px] w-[18px]">
    //           {user?.plan === 1 ? <Check className="h-full w-full" /> : null}
    //         </div>
    //       </div>
    //     </div>
    //   )}

    //   {planType === 1 && (
    //     <div className="mb-20 flex gap-[30px] font-default">
    //       <div
    //         className="cursor-pointer flex justify-center items-center"
    //         onClick={() => setUser({ ...user, plan: 2 })}
    //       >
    //         Basic
    //         <div className="ml-2 mt-[2px] h-[12px] w-[18px]">
    //           {user?.plan === 2 ? <Check className="h-full w-full" /> : null}
    //         </div>
    //       </div>
    //       <div
    //         className="cursor-pointer flex justify-center items-center"
    //         onClick={() => setUser({ ...user, plan: 3 })}
    //       >
    //         Professional
    //         <div className="ml-2 mt-[2px] h-[12px] w-[18px]">
    //           {user?.plan === 3 ? <Check className="h-full w-full" /> : null}
    //         </div>
    //       </div>
    //     </div>
    //   )}

    //   <div className="font-default flex">
    //     <div
    //       className={`mr-[43px] cursor-pointer ${user?.plan !== undefined ? "font-default" : "font-gray"}`}
    //       onClick={() => {
    //         if (user?.plan !== undefined) {
    //           handleStripePayment(user.plan);
    //         }
    //       }}
    //     >
    //       Next
    //     </div>
    //     <div className="cursor-pointer" onClick={() => setStep(2)}>
    //       Go back
    //     </div>
    //   </div>
    // </div>

    <div className="font-default space-y-[96px]">
      <div className="flex justify-between space-x-[100px] sm:space-x-[150px]">
        <p>Select subscription</p>
        <button
          onClick={() => {
            // setStep(4);

            if (user?.plan !== undefined) {
              handleStripePayment(user.plan);
            }
          }}
        >
          Confirm
        </button>
      </div>

      <div className="flex flex-col justify-between space-y-[33px]">
        <div className="flex justify-between space-x-[150px] items-center">
          <button onClick={() => setUser({ ...user, plan: 0 })} className="flex items-center">
            Basic plan
            <div className="ml-2 h-[12px] w-[18px]">
              {user?.plan === 0 && <Check className="h-full w-full mt-[2px]" />}
            </div>
          </button>
          <p>$5</p>
        </div>

        <div className="flex justify-between space-x-[150px] items-center">
          <button onClick={() => setUser({ ...user, plan: 1 })} className="flex items-center">
            Pro plan
            <div className="ml-2 h-[12px] w-[18px]">
              {user?.plan === 1 && <Check className="h-full w-full mt-[2px]" />}
            </div>
          </button>
          <p>$20</p>
        </div>

        <div className="flex justify-between space-x-[150px]">
          <button>Learn more</button>
        </div>
      </div>
    </div>
  );

};

export default Step3;
