import { useEffect, useState } from "react";
import DizeeInput from "../../../components/DizeeInput";
import { verifyEmailOTP, verifyOTP } from "../../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";

const StepEmailVerify = ({ setStep }) => {
    const dispatch = useDispatch();
    const { email } = useSelector((state) => state.user);
    const { emailOtpVerified } = useSelector((state) => state.auth);
    const [code, setCode] = useState("");

    useEffect(() => {
        if (code.length === 6) {
            dispatch(verifyEmailOTP({ code, email })).then((res) => {
                if (res.payload.status === 200) setStep(0);
            });
        }
    }, [code, dispatch, email]);
    // useEffect(() => {
    //     if (emailOtpVerified) setStep(0);
    // }, [emailOtpVerified, setStep]);
    return (
        <div>
            <div className="font-default mb-9">Verify your email</div>
            <div className="mb-20">
                <DizeeInput
                    placeholder={"Enter the code"}
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                />
            </div>
        </div>
    );
};

export default StepEmailVerify;
