import { useEffect, useState } from "react";


const Step12 = ({ setStep, setUser, user }) => {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (user?.avatar) {
      if (typeof user.avatar === "string") {
        setPreview(user.avatar);
      } else if (user.avatar instanceof Blob) {
        setPreview(URL.createObjectURL(user.avatar));
      }
    }
  }, [user?.avatar]);

  const changeAvatar = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreview(URL.createObjectURL(selectedFile));
      setUser({ ...user, avatar: selectedFile });
    }
  };

  // const uploadAvatar = async () => {
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('image', file);

  //     try {
  //       const res = await axios.post('/upload', formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       });

  //       const { filePath } = res.data;
  //       setUser({ ...user, avatar: filePath });
  //       return true; // Return true if the upload is successful
  //     } catch (err) {
  //       console.error("Error uploading file:", err.response?.data?.message || err.message);
  //       return false; // Return false if the upload fails
  //     }
  //   }
  //   return true; // If no file is selected, consider it as successful
  // };

  const handleNext = async () => {
    setStep(13);
    // const isUploaded = await uploadAvatar();
    // if (isUploaded) {
    // }
  };


  return (
    <div>
      <div
        className="font-default mb-9 cursor-pointer"
        onClick={() => document.getElementById("avatar").click()}
      >
        {preview ? "Change profile picture" : "Upload profile picture"}
      </div>
      <input
        type="file"
        className="hidden"
        id="avatar"
        onChange={changeAvatar}
      />
      <div className="profile-picture mb-9 min-h-[100px]">
        <div className="avatar">
          {preview && (
            <img
              src={preview}
              className="h-[80px] w-[80px] rounded-full"
              alt="avatar"
            />
          )}
        </div>
      </div>
      <div className="font-default flex">
        <div
          className={`mr-[43px] cursor-pointer ${(file || user?.avatar) ? 'font-default' : 'font-gray'}`}
          onClick={() => {
            if (file || user?.avatar) {
              handleNext();
            }
          }}
        >
          Next
        </div>

        <div className="cursor-pointer" onClick={() => setStep(11)}>
          Go back
        </div>
      </div>
    </div>
  );
};

export default Step12;
