import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { loginPhoneNumberVerify } from '../../store/auth/authThunk';
import { useLocation, useNavigate } from 'react-router';
import DizeeInput from '../../components/DizeeInput';
import Layout from '../../layout';
import { clearAlerts } from '../../store/alert/alertSlice';

export default function Mobile() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { email } = location.state || {};
    const [phone, setPhone] = useState("");
    const navigation = useNavigate();
    const onChangePhone = (e) => {
        setPhone(e.target.value);
    };

    const sendVerifyCode = () => {
        dispatch(clearAlerts())
        dispatch(loginPhoneNumberVerify({ phone, email })).then((res) => {
            if (res?.payload?.status === 200) {
                navigation("/login-otp", { state: { email, phone } });
            }

        });
    };

    return (
        <Layout>
            <div>
                <div className="font-default mb-9">Enter your phone number</div>
                <div className="mb-20">
                    <DizeeInput
                        placeholder={"Mobile phone #"}
                        value={phone}
                        onChange={onChangePhone}
                    />
                </div>
                <div className="font-default cursor-pointer" onClick={sendVerifyCode}>
                    Send verification code
                </div>
            </div>
        </Layout>
    );
}
