import axios from "../../../utils/axios";
import DizeeInput from "../../../components/DizeeInput";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { checkUserName } from "../../../store/auth/authThunk";
import Check from "../../../svg/Check";

const Step7 = ({ setStep, setUser, user }) => {
  const dispatch = useDispatch();
  const [available, setAvailable] = useState(false);
  const { checkusername } = useSelector((state) => state.auth);

  const onChangeUserName = async (e) => {
    try {
      setUser({ ...user, username: e.target.value });
      if (e.target.value.length > 0) {
        const res = await axios.post("/auth/checkUserName", {
          username: e.target.value,
        });
        if (!res.data.data.exist) {
          dispatch(checkUserName(true));
          setAvailable(true);
        } else {
          dispatch(checkUserName(false));
          setAvailable(false);
        }
      } else {
        dispatch(checkUserName(false));
        setAvailable(false);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <div>
      <div className="font-default mb-9">What should we call you?</div>
      <div className="mb-20 flex font-default">
        <DizeeInput
          placeholder={"Add a username"}
          value={user?.username}
          onChange={onChangeUserName}
        />
        <div className="ml-2 mt-1 h-[12px] w-[18px] flex items-center justify-center">
          {user.username !== undefined && user.username !== "" && (available || checkusername) && (
            <Check className="h-full w-full" />
          )}
        </div>
      </div>
      <div className="font-default flex">
        <div className="font-default flex">
          <div
            className={`cursor-pointer mr-[43px] ${user.username !== undefined && (available || checkusername)
              ? "font-default"
              : "font-gray"
              }`}
            onClick={() => {
              if (user.username !== undefined && (available || checkusername)) {
                if (user.professionRole === 'personal') {
                  setStep(12);
                }
                else {
                  setStep(8);
                }
              }
            }}
          >
            Next
          </div>
        </div>

        <div className="cursor-pointer" onClick={() => setStep(6)}>
          Go back
        </div>
      </div>
    </div>

  );
};

export default Step7;
