import React from 'react'

export default function Songkick(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            {...props}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#fff"
                    d="M4.094 11.738c-1.16 0-2.108-.212-2.874-1.002l1.2-1.192c.394.394 1.088.533 1.697.533.741 0 1.087-.245 1.087-.68 0-.183-.038-.331-.15-.449-.093-.096-.252-.17-.496-.202l-.91-.129c-.665-.095-1.182-.318-1.519-.67-.346-.36-.525-.871-.525-1.52-.019-1.385 1.021-2.395 2.746-2.395 1.088 0 1.905.254 2.554.905l-1.172 1.17c-.478-.479-1.106-.447-1.434-.447-.65 0-.916.373-.916.703 0 .095.032.234.15.35.094.096.252.193.52.225l.917.127c.681.096 1.172.31 1.49.628.404.394.563.956.563 1.66 0 1.543-1.33 2.386-2.925 2.386l-.003-.002Zm8.914-10.08C11.986.896 10.856.383 9.431.148v6.798l2.038-2.837h2.228l-2.56 3.575s.59.943.947 1.503c.367.562.712.712 1.229.712h.356v1.754h-.545c-1.133 0-1.813-.206-2.325-.984-.315-.507-1.36-2.148-1.36-2.148v3.122H7.575V0H7.5C5.093 0 3.432.582 1.993 1.657-.082 3.212.001 5.282.001 7.5c0 2.217-.083 4.292 1.992 5.845C3.432 14.422 5.093 15 7.5 15c2.408 0 4.069-.58 5.508-1.657C15.082 11.79 15 9.716 15 7.499c0-2.216.083-4.287-1.992-5.841Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
            </defs>
        </svg>
    )

}
