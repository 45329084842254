import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Layout from "../../layout";
import DizeeInput from "../../components/DizeeInput";
import { checkEmail as Check, sendOtpToEmailThunk } from "../../store/auth/authThunk";
import { useDispatch, useSelector } from "react-redux";
import { clearAlerts } from "../../store/alert/alertSlice";
import { DizeeInput2 } from "../../components/DixeeInput2";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { checkemail } = useSelector((state) => state.auth);


  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    dispatch(clearAlerts())

    if (email.length) dispatch(Check(email));
  }, [email, dispatch]);

  const resetEmail = () => {
    setEmail("");
  };

  const handleCreateAccount = () => {
    if (!isValidEmail(email)) return
    dispatch(sendOtpToEmailThunk(email)).then((res) => {
      if (res.payload.status === 200) {
        navigate(`/signup?email=${email}`)
      }
    })
  }

  return (
    <Layout>
      <div>
        <div className="font-default mb-9">Login or sign up to get started</div>
        <div className="mb-20">
          <DizeeInput2
            placeholder={"Enter your email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={"dizee-input w-full"}
          />
        </div>
        {email.length > 0 && isValidEmail(email) && (
          <>
            {!checkemail ? (
              <div
                className="font-default cursor-pointer"
                onClick={() => handleCreateAccount()}
              >
                Create a new account
              </div>
            ) : (
              <div className="font-default flex">
                <div className="mr-[43px] cursor-pointer" onClick={() => navigate('/login', { state: { email } })}>Login</div>
                <div className="cursor-pointer" onClick={resetEmail}>Reset</div>
              </div>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Home;
