import Check from "../../../svg/Check";
const Step2 = ({ setStep, setUser, user }) => {

  return (
    <div>
      <div className="font-default mb-9">What describes you best?</div>
      <div className="mb-20 flex gap-[30px] font-default">
        <div
          className="cursor-pointer flex"
          onClick={() => setUser({ ...user, professionRole: 'personal' })}
        >
          <div>Personal</div> {user.professionRole === 'personal' && <Check className="h-[12px] w-[18px] ml-2" />}
        </div>
        <div
          className="cursor-pointer flex"
          onClick={() => setUser({ ...user, professionRole: 'professional' })}
        >
          Professional {user.professionRole === 'professional' && <Check className="h-[12px] w-[18px] ml-2" />}
        </div>
      </div>
      <div className={`cursor-pointer ${user.professionRole !== undefined ? 'font-default' : 'font-gray'}`} onClick={() => {
        if (user.professionRole !== undefined) {
          if (user.professionRole === 'personal') {
            setStep(7);
          }
          else {
            setStep(3);
          }
        }
      }} >
        Next
      </div>
    </div>
  );
};

export default Step2;
