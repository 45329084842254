import React from 'react'

export default function Shopify(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            {...props}
        >
            <g fill="#fff" clipPath="url(#a)">
                <path d="M12.289 2.769c-.06 0-1.115-.025-1.115-.025s-.887-.87-.971-.954a.227.227 0 0 0-.12-.06v13.258l3.968-.991s-1.619-11.035-1.63-11.12a.14.14 0 0 0-.132-.108Z" />
                <path
                    fillRule="evenodd"
                    d="M12.289 2.769c.06 0 .12.048.131.12.004.02.12.81.289 1.96.468 3.189 1.345 9.151 1.354 9.16L10.095 15 .937 13.405s1.103-8.521 1.14-8.823c.047-.4.06-.411.479-.544.012-.012.587-.194 1.414-.448.096-.64.408-1.462.815-2.115C5.36.545 6.08.025 6.8.001c.371-.012.683.109.923.374a.118.118 0 0 0 .024.03c.009.01.018.019.024.03.048-.011.084-.011.12-.011.55 0 1.018.326 1.33.93.096.194.168.375.216.532.275-.084.443-.133.443-.133.06-.024.24-.048.324.037l.97.954s1.056.025 1.116.025Zm-3.655-.631c.12-.039.236-.076.346-.107-.12-.374-.383-.99-.923-1.1.168.436.216.955.216 1.318.124-.036.244-.074.36-.111Zm-1.5.468c.222-.07.44-.137.648-.2.012-.41-.036-1.003-.252-1.426a1.928 1.928 0 0 0-.515.387c-.336.374-.624.942-.768 1.51.299-.089.597-.181.887-.271ZM7.242.593a.818.818 0 0 0-.431-.109c-1.115.037-2.086 1.79-2.338 2.95l1.259-.387c.144-.738.491-1.511.947-2.007.18-.193.372-.338.563-.447Zm.18 5.97.456-1.74s-.396-.205-1.175-.145c-2.014.121-2.925 1.547-2.84 2.95.06.935.607 1.327 1.087 1.67.375.268.708.507.734.952a.582.582 0 0 1-.563.617c-.647.048-1.45-.569-1.45-.569l-.312 1.33s.803.87 2.265.773c1.21-.072 2.05-1.051 1.966-2.49-.073-1.104-.844-1.602-1.451-1.995-.397-.256-.724-.467-.743-.772 0-.145 0-.713.9-.774a2.484 2.484 0 0 1 1.126.194Z"
                    clipRule="evenodd"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h15v15H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
