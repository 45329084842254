import DizeeInput from "../../../components/DizeeInput";

const Step10 = ({ setStep, setUser, user }) => {
  return (
    <div>
      <div className="font-default mb-9">Confirm or edit your handles</div>
      <div className="mb-9 flex font-default">
        IG @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.ig}
          onChange={(e) =>
            setUser({ ...user, social: { ...user.social, ig: e.target.value } })
          }
        />
      </div>
      <div className="mb-9 flex font-default">
        TT @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.tt}
          onChange={(e) =>
            setUser({ ...user, social: { ...user.social, tt: e.target.value } })
          }
        />
      </div>
      <div className="mb-9 flex font-default">
        X &nbsp; @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.x}
          onChange={(e) => setUser({ ...user, social: { ...user.social, x: e.target.value } })}
        />
      </div>
      <div className="mb-9 flex font-default">
        FB @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.fb}
          onChange={(e) => setUser({ ...user, social: { ...user.social, fb: e.target.value } })}
        />
      </div>
      <div className="mb-9 flex font-default">
        SC @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.sc}
          onChange={(e) => setUser({ ...user, social: { ...user.social, sc: e.target.value } })}
        />
      </div>
      <div className="mb-9 flex font-default">
        YT @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.yt}
          onChange={(e) => setUser({ ...user, social: { ...user.social, yt: e.target.value } })}
        />
      </div>
      <div className="mb-9 flex font-default">
        SF @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.sf}
          onChange={(e) => setUser({ ...user, social: { ...user.social, sf: e.target.value } })}
        />
      </div>
      <div className="mb-9 flex font-default">
        AM @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.am}
          onChange={(e) => setUser({ ...user, social: { ...user.social, am: e.target.value } })}
        />
      </div>
      <div className="mb-9 flex font-default">
        Th @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.th}
          onChange={(e) => setUser({ ...user, social: { ...user.social, th: e.target.value } })}
        />
      </div>
      <div className="mb-20 flex font-default">
        LI @
        <DizeeInput
          placeholder={"youraccount"}
          value={user?.social?.li}
          onChange={(e) => setUser({ ...user, social: { ...user.social, li: e.target.value } })}
        />
      </div>
      <div className="font-default flex">
        <div className="mr-[43px] cursor-pointer" onClick={() => setStep(10)}>
          Next
        </div>
        <div className="cursor-pointer" onClick={() => setStep(8)}>
          Go back
        </div>
      </div>
    </div>
  );
};

export default Step10;
